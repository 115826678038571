import React from 'react';
import { Layout } from '@components/global/Layout';
import { MarkdownRemark } from '@model/generated';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Meta } from '@components/global/Meta';

type Props = {
  pageContext: {
    item: MarkdownRemark;
    navigationItems: Array<{ title: string; to: string }>;
  };
};

const Page: React.FC<Props> = (props) => {
  const { pageContext } = props;

  return (
    <Layout title="Digital Diary" navigationItems={pageContext.navigationItems}>
      <div className="flex">
        <div className="w-full px-32">
          <GatsbyImage
            className="max-h-512 w-full mt-32"
            objectFit="contain"
            objectPosition="left top"
            image={
              pageContext.item.frontmatter.image.childImageSharp.gatsbyImageData
            }
            alt={pageContext.item.frontmatter.title}
          />
          {pageContext.item.html && (
            <div
              className="markdown-container"
              dangerouslySetInnerHTML={{
                __html: pageContext.item.html,
              }}
            />
          )}
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>
          {pageContext.item.frontmatter.title}
        </h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  const { pageContext } = props;
  const paragraph = (pageContext.item.htmlAst?.children as Array<{
    type: string;
    tagName: string;
    children: Array<{ value: string; children: Array<{ value: string }> }>;
  }>).filter((item) => item.type == 'element' && item.tagName == 'p')[0];
  const description =
    paragraph.children[0].value || paragraph.children[0].children[0].value;

  return (
    <Meta
      description={description}
      title={pageContext.item.frontmatter.title}
      publicURL={pageContext.item.frontmatter.image.publicURL}
    />
  );
};

export default Page;
