import React from 'react';

interface Props {
  title?: string;
  publicURL?: string;
  description?: string;
}

export const Meta: React.FC<Props> = (props) => {
  const {
    publicURL = '/images/wunderkammer.png',
    description = 'Die Wunderkammer der Zukunft ist ein Projekt der Museen der Stadt Lüdenscheid.',
  } = props;

  const image = `${
    process.env.GATSBY_SITE_URL || `https://wunderkammer-zukunft.de`
  }${publicURL}`;

  const title = props.title ? `Wunderkammer | ${props.title}` : 'Wunderkammer';

  return (
    <>
      <title>{title}</title>
      <meta name="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta name="image" content={image} />
      <meta name="og:image" content={image} />
      <meta name="twitter:image" content={image} />
      <meta name="og:image:alt" content={title} />
      <meta name="twitter:image:alt" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
    </>
  );
};
